// ローディング判定
jQuery(window).on("load", function() {
  jQuery("body").attr("data-loading", "true");
  jQuery(".mv-list").slick({
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 3000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    fade: true,
    lazyLoad: "progressive"
  });
});

jQuery(function() {
  // スクロール判定
  jQuery(window).on("scroll", function() {
    let scrollTop = jQuery(this).scrollTop();
    let windowHeight = jQuery(this).height();
    let documentHeight = jQuery(document).height();

    if (100 < scrollTop) {
      jQuery("body").attr("data-scroll", "true");
    } else {
      jQuery("body").attr("data-scroll", "false");
    }

    if (documentHeight - (windowHeight + scrollTop) <= 0) {
      jQuery("body").attr("data-scroll-bottom", "true");
    } else {
      jQuery("body").attr("data-scroll-bottom", "false");
    }
  });

  /* ドロワー */
  jQuery(".js-drawer").on("click", function(e) {
    e.preventDefault();
    let targetClass = jQuery(this).attr("data-target");
    let ariaControls = jQuery(this).attr("aria-controls");
    jQuery("." + targetClass).toggleClass("is-checked");
    jQuery(body).toggleClass("is-checked");

    if (jQuery("#" + ariaControls).attr("aria-hidden") === "true") {
      jQuery("#" + ariaControls).attr("aria-hidden", "false");
    } else {
      jQuery("#" + ariaControls).attr("aria-hidden", "ture");
    }

    if (jQuery(this).attr("aria-expanded") === "true") {
      jQuery(this).attr("aria-expanded", "false");
    } else {
      jQuery(this).attr("aria-expanded", "ture");
    }
    return false;
  });

  jQuery(".sp-btn").on("click", function() {
    jQuery(this)
      .next()
      .slideToggle();
  });

  /* スムーススクロール */
  jQuery('a[href^="#"]').click(function() {
    let header = jQuery("#header").height();
    let speed = 300;
    let id = jQuery(this).attr("href");
    let target = jQuery("#" == id ? "html" : id);
    let position = jQuery(target).offset().top - header;
    if ("fixed" !== jQuery("#header").css("position")) {
      position = jQuery(target).offset().top;
    }
    if (0 > position) {
      position = 0;
    }
    jQuery("html, body").animate(
      {
        scrollTop: position
      },
      speed
    );
    return false;
  });

  /* 電話リンク */
  let ua = navigator.userAgent;
  if (ua.indexOf("iPhone") < 0 && ua.indexOf("Android") < 0) {
    jQuery('a[href^="tel:"]')
      .css("cursor", "default")
      .on("click", function(e) {
        e.preventDefault();
      });
  }
});

jQuery(function() {
  jQuery(".header-item a").each(function() {
    if (this.href == location.href) {
      jQuery(this)
        .parents("li")
        .addClass("current");
    }
    var path = location.pathname;
    if (path == "/") {
      jQuery(".header-item")
        .eq(0)
        .addClass("current");
    }
  });
});

jQuery(".mv-list").on("beforeChange", function(event, slick, currentSlide, nextSlide) {
  if (nextSlide > 0) {
    jQuery(".mv-list").slick("slickSetOption", "autoplaySpeed", 2000, true);
  }
});
